import {
	BookingIcon,
	Dashboard,
	EquipmentIcon,
	ReportsIcon,
	ClientsIcon,
	ProjectsIcon,
	UserIcon,
	SignOutIcon,
	AdminPanelIcon,
	TeamBoardIcon,
	PerformanceReviewIcon,
	DepartmentIcon,
	TimeSheetIcon,
	PayloadIcon,
} from '@mms/mms-ui-library';

import {
	AllRoles,
	chiefsAndSA,
	managerRoles,
	Roles,
	Routes,
} from '@/constants/index';

import { NavigationItem } from './types';

const firstSectionTabs: NavigationItem[] = [
	{
		id: 1,
		label: 'Dashboard',
		icon: <Dashboard />,
		payload: {
			slug: Routes.dashboard,
			roles: AllRoles,
		},
	},
	{
		id: 2,
		label: 'Timesheet',
		icon: <TimeSheetIcon />,
		payload: {
			slug: Routes.timeSheet,
			roles: AllRoles,
		},
	},
	{
		id: 3,
		label: 'Reports',
		icon: <ReportsIcon />,
		payload: {
			slug: Routes.reports,
			roles: AllRoles,
		},
	},
	{
		id: 4,
		label: 'Booking',
		icon: <BookingIcon />,
		payload: {
			slug: Routes.booking,
			roles: AllRoles,
		},
	},
];

const secondSectionTabs: NavigationItem[] = [
	{
		id: 5,
		label: 'Departments',
		icon: <DepartmentIcon />,
		payload: {
			slug: Routes.departments,
			roles: [Roles.CTO, Roles.CEO, Roles.HD],
		},
	},
	{
		id: 6,
		label: 'Clients',
		icon: <ClientsIcon />,
		payload: {
			slug: Routes.clients,
			roles: [Roles.CTO, Roles.CEO, Roles.HD],
		},
	},
	{
		id: 7,
		label: 'Projects',
		icon: <ProjectsIcon />,
		payload: {
			slug: Routes.projects,
			roles: [Roles.CTO, Roles.CEO, Roles.HD],
		},
	},
	{
		id: 8,
		label: 'Users',
		icon: <UserIcon />,
		payload: {
			slug: Routes.users,
			roles: chiefsAndSA,
		},
	},
	{
		id: 9,
		label: 'Equipment',
		icon: <EquipmentIcon />,
		payload: {
			slug: Routes.equipment,
			roles: chiefsAndSA,
		},
	},
];

const thirdSectionsTabs: NavigationItem[] = [
	{
		id: 10,
		label: 'Teamboard',
		icon: <TeamBoardIcon />,
		payload: {
			slug: Routes.teamBoard,
			roles: [Roles.CTO, Roles.CEO, Roles.HD],
		},
	},
	{
		id: 11,
		label: 'Performance review',
		icon: <PerformanceReviewIcon />,
		payload: {
			slug: Routes.performanceReview,
			roles: [Roles.CTO, Roles.CEO, Roles.HD],
		},
	},
	{
		id: 12,
		label: 'Payload',
		icon: <PayloadIcon />,
		payload: {
			slug: Routes.payload,
			roles: managerRoles,
		},
	},
	{
		id: 13,
		label: 'Admin Panel',
		icon: <AdminPanelIcon />,
		payload: {
			slug: Routes.adminPanel,
			roles: managerRoles,
		},
	},
	{
		id: 14,
		label: 'Log Out',
		icon: <SignOutIcon />,
		payload: {
			slug: Routes.signOut,
			roles: AllRoles,
		},
	},
];

export const tabsConfig: NavigationItem[][] = [
	firstSectionTabs,
	secondSectionTabs,
	thirdSectionsTabs,
];

export const closedDrawerWidth = 56;

export function getTabIdBySlug(slug: string): number | null {
	let foundId: number | null = null;
	tabsConfig.forEach((item) => {
		const id = item.find((tab) => slug.startsWith(tab.payload?.slug || ''))?.id;

		if (id) {
			foundId = id;
		}
	});

	return foundId;
}
