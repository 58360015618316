import { SelectionModalOption } from '@/components/Grid/renderers/header/types';
import { PERIOD } from '@/constants/date';

export const START_MONTH_COLUMN_INDEX = 8;
export const HEIGHT_CELL = 59;
export const HEIGHT_EXPANDED_CELL = 50;

export const PROJECT_NAME_MAX_WIDTH = 45;
export const CLIENT_NAME_MAX_WIDTH = 80;
export const IGNORE_COLUMN_INDEXES = [null, 1, 2, 3, 4, 5, 6, 7, 20];
export const HEAD_IGNORE_COLUMN_INDEXES = [null, 1, 2, 3, 4, 5, 6, 7, 19];
export const PROJECTS_COLUMN_INDEX = 7;
export const BASE_LINE_HEIGHT = 1;
export const BASE_LINE_CLAMP = 3;
export const COLUMNS_COUNT = 21;
export const MAX_PROJECTS_FITS = 3;
export const PROJECTS_ROW_HEIGHT = 17.5;
export const PAGE_SIZE = 25;
export const INITIAL_SPECIALIZATION_TITLE = 'All Specializations';
export const IGNORE_TOTAL_COLUMN_INDEXES = [1, 2, 3, 4, 5];

export const FILTERS_FIELD_NAME: Record<string, string> = {
	id: 'UserId',
	employee: 'EmployeeFullName',
};

export const TOTAL_ROW_ID = -9999;

export const DEPARTMENT_CELL_CHILD_WIDTH = '95%';

export enum MonthCellColors {
	red = '#F8CCCC',
	green = '#D0EFDE',
	purple = '#D9CCFF',
	orange = '#FCE4CC',
}

const idSettings = {
	id: {
		width: 67,
	},
};

const departmentSettings = {
	department: {
		width: 67,
	},
};

const anotherSettings = {
	employee: {
		width: 150,
		fixed: true,
	},
	comments: {
		width: 150,
		fixed: true,
	},
	needProject: {
		width: 57,
	},
	projects: {
		width: 180,
	},
	jan: {
		width: 67,
	},
	feb: {
		width: 67,
	},
	mar: {
		width: 67,
	},
	apr: {
		width: 67,
	},
	may: {
		width: 67,
	},
	jun: {
		width: 67,
	},
	jul: {
		width: 67,
	},
	aug: {
		width: 67,
	},
	sep: {
		width: 67,
	},
	oct: {
		width: 67,
	},
	nov: {
		width: 67,
	},
	dec: {
		width: 67,
	},
	plan: {
		width: 300,
		fixed: true,
	},
};

export const PAYLOAD_COLUMNS_WIDTH = {
	...idSettings,
	...departmentSettings,
	...anotherSettings,
};
export const HEAD_PAYLOAD_COLUMNS_WIDTH = {
	...idSettings,
	...anotherSettings,
};
export const LOGIC_OPTIONS: Array<SelectionModalOption> = [
	{
		id: 'Yes',
		description: 'Yes',
		disabled: false,
	},
	{
		id: 'No',
		description: 'No',
		disabled: false,
	},
];
export const PAYLOAD_GRID_COLUMNS = {
	payloadId: {
		key: 'id',
		name: 'Id',
		sortable: true,
	},
	payloadDepartment: {
		key: 'department',
		name: 'Dep.',
	},
	payloadEmployee: {
		key: 'employee',
		name: 'Employee',
		sortable: true,
	},
	payloadComment: {
		key: 'comments',
		name: 'Comments',
	},
	payloadProject: {
		key: 'projects',
		name: 'Projects',
	},
	payloadNp: {
		key: 'needProject',
		name: 'NP',
		sortable: false,
	},
	...PERIOD.map((month) => ({
		key: month.toLowerCase(),
		name: month,
		sortable: false,
	})),
	payloadPlan: {
		key: 'plan',
		name: 'Plan',
	},
};
