import { LOCATION } from '@/constants/location';

export const API_BASE_URL: { [key in LOCATION]: string } = {
	'https://rc.modsen.app': 'https://reports.modsen.app:8080',
	'https://qa.modsen.app': 'https://reports.modsen.app:8182',
	'https://stage.modsen.app': 'https://reports.modsen.app:8181',
	'https://modsen.app': 'https://reports.modsen.app:443',
	'http://localhost:3000': 'https://mms-api.development.modsen.app',
	'https://mms-fe.development.modsen.app':
		'https://mms-api.development.modsen.app',
	'https://mms-fe.test.modsen.app': 'https://mms-api.test.modsen.app',
	'https://mms-fe.staging.modsen.app': 'https://mms-api.staging.modsen.app',
	'https://mms-pp-fe.development.modsen.app':
		'https://mms-pp-api.development.modsen.app',
};
